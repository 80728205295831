import React, {useRef, useEffect, useState} from 'react'
import printer_img from "./media/3d_printer_earning_cryptocurrency-PhotoRoom.png";
import hive_logo from "./media/HiveFab logo transp bkg.png"
import x_logo from "./media/Xlogo.svg"
import ig_logo from "./media/ig_logo.svg"
import discord_logo from "./media/discord-logo-white.svg"
import whitepaper from "./Hive Fabrication Protocol Construction Paper.pdf"

export const section_content_style = { 
  display:'flex',
  backgroundColor:'var(--splash-main_bgcolor)', 
  marginTop:'40px',
  marginBottom:'30px',
  marginLeft:'2%',
  marginRight:'2%',
  alignItems:'center',
  justifyContent:'center',
  gap:'3%'
}

const column_style = {
  width:'28%', 
  maxWidth:'600px', 
  display:'flex', 
  flexDirection:'column', 
  wordWrap:'break', alignItems:'center', 
  justifyContent:'center',
  lineHeight:'1px',
  }

const column_2_style = {
  width:'28%', 
  maxWidth:'600px', 
  display:'flex', 
  flexDirection:'column', 
  wordWrap:'break', alignItems:'center', 
  justifyContent:'center',
  lineHeight:'1px',
  }

const link_style = {
  color:'#FFFFFF', 
  fontSize:'1.5rem',
  justifyContent:'center', 
  display:'flex',
  fontFamily:'RobotoFont',
  lineHeight:'50px',
  textDecoration: "none",
}

const link_2_style = {
  color:'#FFFFFF', 
  fontSize:'1.5rem',
  justifyContent:'center', 
  display:'flex',
  fontFamily:'RobotoFont',
  lineHeight:'50px',
  textDecoration: "none",
  marginTop:'10px',
  marginBottom:'10px'
}

const FooterTab = () => {
  const style_prop = {
    display: "block",
    position: "relative",
    transform: "translate3d(0px, 0px, 0px)",
    backgroundColor: "none",
    fonSize:'80px',
  }

  return (
    <div id="footer" style={{display:'flex', flexDirection:'column', marginTop:'90px', color:'#FFFFFF', backgroundColor:'var(--splash-main_bgcolor)'}}>
      <div id="top-portion">
        <div style={{display:'flex', flexDirection:'row'}}>
        <div style={{width:'45%', display:'flex',justifyContent:'center'}}>
          <img id="print_graphic" src={printer_img} style={{width:'60%'}} /> 
          </div>
         <div style={{width:'45%', maxWidth:'900px',display:'flex',alignItems:'center'}}>
         <h1 id="s-0-h" style={{display:'flex',flexDirection:'column'}}>
            <div class="parent-line" >
              <p id="dtag" style={{fontSize:'40px',margin:'0px'}}>Step Into the Future of 3D Printing</p>
            </div>
            <br></br>
            <div style={{paddingLeft:'30px',display:'flex',maxWidth:'900px'}}>
            <p id="smtext" style={{fontFamily:'RobotoFont'}}>Empower your creativity in a decentralized world. Create, earn, and connect with like-minded enthusiast and pioneers.</p>
            </div>
          </h1>
          </div>
          
         </div> 
       <div style={{display:'flex', justifyContent:'center'}}>
         <ul className="nav-desktop__side">
            <li>
              <a href="https://app.hivefab.com/" target="_blank" className="cta yellow">
              <span>Explore Jobs</span>
               <div className="cta_bg" ></div>
               <div className="cta_base_bg"></div>
              </a>
            </li>
          </ul>  
          </div> 
      </div>
      <div id="footer-portion" >
        <div style={section_content_style}>
          <div style={column_style}>
            <img id="nav_logo" src={hive_logo} />
          </div>
          <div style={column_style}>
            <h2 style={{color:'var(--hive-blue)', fontSize:'1.8rem', justifyContent:'center', display:'flex'}}>Resources:</h2>
            <a href={whitepaper} style={link_style} target="blank" download="Hive Protocol">White Paper</a>
            <a href="/terms" style={link_style} target="blank" >Terms of Use</a>
            <a href="/policy" style={link_style} target="blank" >Privacy Policy</a>
          </div>
          <div style={column_2_style}>
            <h2 style={{color:'var(--hive-blue)', fontSize:'1.8rem', justifyContent:'center', display:'flex'}}>Community:</h2>

                <a style={link_2_style} href="https://discord.com/channels/1032093195124875384/1032093195124875387" target="blank">
                    <img id="discord_logo" src={discord_logo} style={{ width: '120px' }} alt="Logo" />
                    </a>

                <a style={link_2_style} href="https://www.instagram.com/hivefabrication/" target="blank">
                  <img id="ig_logo" src={ig_logo} style={{ width: '30px' }} alt="Logo" />
                  </a>

                <a style={link_2_style} href="https://twitter.com/hivefabrication" target="blank">
                  <img id="x_logo" src={x_logo} style={{ width: '30px' }} alt="Logo" />
                  </a>

              {/*<li className="footerlist">
                <a className="footerlink" href="/about">Reddit</a>
              </li>*/}

          </div>
        </div>
      </div>    
    </div>
  )
}


export default FooterTab